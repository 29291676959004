<template>

  <div>
       <add-new
        :is-add-new-admin-sidebar-active.sync="isAddNewAdminSidebarActive"
        :is-edit.sync="isEdit"
        :admin-to-update="adminToUpdate"
        @refetch-data="refetchData"
      />
      
      <update-admin-password
        :is-update-admin-password-sidebar-active.sync="isUpdateAdminPasswordSidebarActive"
        :admin-to-update="adminToUpdate"
      />

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >
      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="3"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Show</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entries</label>
          </b-col>

          <!-- Referesh -->
          <b-col cols="12"
          md="3">
             <b-button
                variant="primary"
                @click="refreshData()"
              >
               <feather-icon icon="RefreshCcwIcon" />
              </b-button>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Search..."
              />
                <b-button
                variant="primary"
                @click="openForm()"
                v-if="$can(Action.Create, Subject.ADMIN)"
              >
                <span class="text-nowrap">Add Admin</span>
              </b-button>               
            </div>
          </b-col>
        </b-row>

      </div>

      <b-table
        ref="refAdminTable"
        class="position-relative"
        :items="admins"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >
      
       <!-- Column: Status -->
       <template #cell(roles)="data">
            <div v-for="role in data.item.roles" :key="role.id" class="text-nowrap">
            <b-badge pill variant="light-primary" class="text-capitalize">
              {{
                  role.name
              }}
            </b-badge>
          </div>
        </template>
      
        <!-- Column: Status -->
        <template #cell(isSuspended)="data">
          <b-badge
            pill
            :variant=" data.item.isSuspended ? 'light-warning' : 'light-primary'"
            class="text-capitalize"
          >
            {{ data.item.isSuspended ? 'Suspended' : 'Active' }}
          </b-badge>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
            variant="button"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >

            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
              <b-dropdown-item @click="editAdmin(data.item)" v-if="$can(Action.Update, Subject.ADMIN)">
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Edit</span>
            </b-dropdown-item>
            <b-dropdown-item @click="updatePassword(data.item)" v-if="$can(Action.Update, Subject.ADMIN)">
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">Update Password</span>
            </b-dropdown-item>
            <b-dropdown-item @click="showSuspensionStatusModal(data.item)" v-if="$can(Action.Update, Subject.ADMIN)">
              <feather-icon :icon="data.item.isSuspended ? 'PlayIcon': 'PauseIcon'" />
              <span class="align-middle ml-50">{{ 
                  data.item.isSuspended ? 'Unsuspend' : 'Suspend'
                }}</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalAdmins"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import { BCard, BRow, BCol, BFormInput, BButton, BTable, BBadge,
      BDropdown, BDropdownItem, BDropdownItemButton, BPagination} from 'bootstrap-vue'
import { ref, onMounted } from '@vue/composition-api'
import useAdmin from './useAdmin'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import AddNew from './components/AddNew.vue'
import UpdateAdminPassword from './components/UpdateAdminPassword.vue'
import store from '@/store'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { avatarText, formatDate } from "@core/utils/filter"
import { Action, Subject } from '@/libs/acl/config'

export default {
  components: {
    AddNew,
    UpdateAdminPassword,
    BDropdown, 
    BDropdownItem, 
    BDropdownItemButton,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BPagination,
    BBadge,
    vSelect
  },
  directives: {
    Ripple,
  },
   data() {
    return {
      adminToUpdate: null,
    }
  },
  methods: {
    removeAdmin(admin) {
         this.$bvModal
        .msgBoxConfirm(`Please confirm that you want to delete ${admin.title}.`, {
          title: 'Confirmation',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Delete',
          cancelTitle: 'No',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if(value) {
            this.onDelete(admin)
          }
        })
    },
    editAdmin(admin) {
      this.adminToUpdate = admin;
      this.openForm(true);
    },
    updatePassword(admin) {
      this.adminToUpdate = admin;
      this.openPasswordForm();
    },
    showSuspensionStatusModal(admin) {
      let status = admin.isSuspended ? "Activate" : "Suspend";
      this.$bvModal
        .msgBoxConfirm(`Please confirm that you want to update ${admin.fullName}'s suspension status.`, {
          title: 'Confirmation',
          size: 'sm',
          okVariant: 'primary',
          okTitle: status,
          cancelTitle: 'No',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if(value) {
            this.updateSuspensionStatus(admin.id)
          }
        })
    },
  },
  setup() {

    onMounted(() => {
      fetchAdmins()
    })
    const isAddNewAdminSidebarActive = ref(false)
    const isUpdateAdminPasswordSidebarActive = ref(false)
    const isEdit = ref(false);
    const toast = useToast()


    const openForm = (update = false) => {
      isEdit.value = update;
      isAddNewAdminSidebarActive.value = true;
    };

    const openPasswordForm = () => {
      isUpdateAdminPasswordSidebarActive.value = true;
    };

     const onDelete = (admin) => {
         deleteAdmin(admin)
        .then(_ => {
          removeAdmin(admin.id)
          refetchData()
        }).catch((message) => {
         toast({
            component: ToastificationContent,
            props: {
              title: 'Error: '+message,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        });
    };

    const {
      fetchAdmins,
      admins,      
      tableColumns,
      perPage,
      currentPage,
      totalAdmins,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refAdminTable,
      refetchData,
      refreshData,
      deleteAdmin,
      removeAdmin,
      resolveAdminStatusBadge,
      updateSuspensionStatus
    } = useAdmin()

    return {
      isAddNewAdminSidebarActive,
      isUpdateAdminPasswordSidebarActive,
      isEdit,

      openForm,
      openPasswordForm,
      fetchAdmins,
      deleteAdmin,
      onDelete,
      admins,
      
      tableColumns,
      perPage,
      currentPage,
      totalAdmins,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refAdminTable,
      refetchData,
      refreshData,
      toast,
      formatDate,
      resolveAdminStatusBadge,
      updateSuspensionStatus,

      // Authorisation
      Action,
      Subject
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
