<template>
  <b-sidebar
    id="add-new-admin-sidebar"
    :visible="isAddNewAdminSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="onFormToggle"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div
        class="
          d-flex
          justify-content-between
          align-items-center
          content-sidebar-header
          px-2
          py-1
        "
      >
        <h5 class="mb-0">{{isEdit ? 'Update Admin' : 'Add New Admin'}}</h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>

      <!-- BODY -->
      <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >
          <!-- Name -->
          <validation-provider
            #default="validationContext"
            name="Name"
            rules="required"
          >
            <b-form-group
              label="Name"
              label-for="name"
              :state="getValidationState(validationContext)"
            >
             <b-form-input
                id="name"
                v-model="adminData.fullName"
                autofocus
                trim
                :state="getValidationState(validationContext)"
                placeholder="Name"
              />
              <b-form-invalid-feedback
                :state="getValidationState(validationContext)"
              >
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Email -->
          <validation-provider name="Email" #default="validationContext" rules="required|email">
            <b-form-group
              label="Email"
              label-for="email"
              :state="getValidationState(validationContext)"
            >
             <b-form-input
                id="email"
                v-model="adminData.email"
                autofocus
                trim
                :state="getValidationState(validationContext)"
                placeholder="Email"
              />
              <b-form-invalid-feedback
                :state="getValidationState(validationContext)"
              >
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- State -->
          <validation-provider
            #default="validationContext"
            name="State"
          >
          <b-form-group label="State" label-for="state">
               <v-select
                v-model="adminData.state"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="states"
                :reduce="(val) => val.value"
                :clearable="false"
                input-id="state"
                :state="getValidationState(validationContext)"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

            <!-- Roles -->
            <validation-provider
            #default="validationContext"
            name="Roles"
            rules="required"
          >
          <b-form-group label="Roles" label-for="role">
               <v-select
                v-model="adminData.roles"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="roles.map(role => ({
                  label: role.name,
                  value: role.id
                }))"
                :reduce="(val) => val.value"
                :clearable="false"
                multiple
                chips
                input-id="roles"
                :state="getValidationState(validationContext)"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

           <!-- Password -->
           <validation-provider v-if="!isEdit"
            #default="validationContext"
            name="Password"
            rules="required|password"
            vid="password"
          >
            <b-form-group
              label="Password"
              label-for="password"
              :state="getValidationState(validationContext)"
            >
             <b-form-input
                id="password"
                v-model="adminData.password"
                :type="passwordFieldType"
                autofocus
                trim
                :state="getValidationState(validationContext)"
                placeholder=".........."
                class="form-control-merge"
              />
                <feather-icon
                  class="cursor-pointer"
                  :icon="passwordToggleIcon"
                  @click="togglePasswordVisibility"
                />
              <b-form-invalid-feedback
                :state="getValidationState(validationContext)"
              >
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

            <!-- Confirm Password -->
            <validation-provider v-if="!isEdit"
            #default="validationContext"
            name="ConfirmPassword"
            rules="required|password|confirmed:password"
          >
            <b-form-group
              label="Confirm Password"
              label-for="confirmPassword"
              :state="getValidationState(validationContext)"
            >
             <b-form-input
                id="confirmPassword"
                v-model="adminData.confirmPassword"
                :type="confirmPasswordFieldType"
                autofocus
                trim
                :state="getValidationState(validationContext)"
                placeholder=".........."
                class="form-control-merge"
              />
                <feather-icon
                  class="cursor-pointer"
                  :icon="confirmPasswordToggleIcon"
                  @click="toggleConfirmPasswordVisibility"
                />
              <b-form-invalid-feedback
                :state="getValidationState(validationContext)"
              >
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              {{isEdit ? 'Update' : 'Add'}}
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Cancel
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BInputGroupAppend,
  BButton,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { onMounted, ref } from "@vue/composition-api";
import { required, alphaNum, email, alpha, password, confirmed } from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import countries from "@/@fake-db/data/other/countries";
import useAdmin from "../useAdmin";
import useRole from '@views/apps/role/useRole';
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { nigerianStates } from '@core/utils/utils'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BInputGroupAppend,
    BButton,
    vSelect,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: "isAddNewAdminSidebarActive",
    event: "update:is-add-new-admin-sidebar-active",
  },
  props: {
    isAddNewAdminSidebarActive: {
      type: Boolean,
      required: true,
    },
    isEdit: {
      type: Boolean,
      required: true,
    },
    adminToUpdate: {
      type: Object,
      required: false,
    }
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      required,
      alphaNum,
      alpha,
      email,
      countries,
      password,
      confirmed
    };
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    confirmPasswordToggleIcon() {
      return this.confirmPasswordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    }
  },
  setup(props, { emit }) {

    onMounted(() => {
      fetchRoles()
    })
  
    const toast = useToast();

    let blankAdminData = {
      id: null,
      fullName: null,
      email: null,
      state: null,
      roles: null,
      password: null,
      confirmPassword: null
    };

    let states = nigerianStates.map(x => { return {label: x, value: x} })

    const adminData = ref(JSON.parse(JSON.stringify(blankAdminData)));
    const resetAdminData = () => {
      adminData.value = JSON.parse(JSON.stringify(blankAdminData));
    };

    const onSubmit = () => {
      if (props.isEdit) {
        dispatchUpdateAdmin();
      } else {
        dispatchAddAdmin();
      }
    };

    const dispatchAddAdmin = () => {
      addAdmin(adminData.value)
        .then((_) => {
          emit("refetch-data");
          emit("update:is-add-new-admin-sidebar-active", false);
        })
        .catch((message) => {
          toast({
            component: ToastificationContent,
            props: {
              title: "Error: " + message,
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        });
    };

    const dispatchUpdateAdmin = () => {
      updateAdmin(adminData.value)
        .then((_) => {
           emit("refetch-data");
           emit("update:is-add-new-admin-sidebar-active", false);
        })
        .catch((message) => {
          toast({
            component: ToastificationContent,
            props: {
              title: "Error: " + message,
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        });
    };

    const onFormToggle = (value) => {
        emit('update:is-add-new-admin-sidebar-active', value)
        if(value) {
            if(props.isEdit) {
                blankAdminData = {...props.adminToUpdate, roles: props.adminToUpdate.roles.map(role => role.id)};
                adminData.value = blankAdminData
            } else {
                props.isEdit = false;
                blankAdminData = {
                    id: null,
                    fullName: null,
                    email: null,
                    state: null,
                    roles: null,
                    password: null,
                    confirmPassword: null
                }; 
                adminData.value = blankAdminData
            }
        }
    }

    const handleDateChange = (event) => {
      console.log(event)
    }

    const { refFormObserver, getValidationState, resetForm } =
      formValidation(resetAdminData);

    const { setAdmin, addAdmin, updateAdmin } = useAdmin();
    const { roles, fetchRoles } = useRole()

    return {
      adminData,
      roles,
      onSubmit,
      handleDateChange,
      states,
      refFormObserver,
      getValidationState,
      resetForm,
      onFormToggle
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

#add-new-driver-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
