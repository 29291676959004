import { ref, watch, computed, reactive } from '@vue/composition-api'
import useJwt from '@/auth/jwt/useJwt'
import  routes from '@/@core/utils/api-routes'
import { paginateArray, sortCompare } from '@core/utils/utils'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

const roles = ref([])
const filteredRoles = ref([])

export default function useRole() {
  // Use toast
  const toast = useToast()

  const refRoleTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'id', sortable: true, stickyColumn: true },
    { key: 'name', sortable: true },
    { key: 'permissions', sortable: true },
    { key: 'actions',  },
  ]
  const perPage = ref(10)
  const totalRoles = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)


  const dataMeta = computed(() => {
    const localItemsCount = refRoleTable.value ? refRoleTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalRoles.value,
    }
  })

  const refetchData = () => {
    console.log("refectchData")
    refRoleTable.value.refresh()
  }
 
  const refreshData = () => {
    roles.value = []
    fetchRoles()
  }

  const resolveRoleStatusBadge = status => {
    if (status === true) return 'success'
    if (status === false) return 'secondary'
  }

  watch([currentPage, perPage, searchQuery], () => {
    const queryLowered = searchQuery.value.toLowerCase()
    const filteredData = roles.value.filter(
    role => role.name.toLowerCase().includes(queryLowered) ||
    role.permissions.some( permission => { 
      return permission.action.toLowerCase().includes(queryLowered) || permission.subject.toLowerCase().includes(queryLowered)
    }))
    
    filteredRoles.value = paginateArray(filteredData, perPage.value, currentPage.value)
    totalRoles.value = filteredData.length
    refetchData()
  })

  function setRole(role) {
    roles.value = roles.value.filter(x => x.id != role.id);
    roles.value.push(role);
    roles.value.sort(function (a, b) {
      return a.id - b.id;
    });

    filteredRoles.value = paginateArray(roles.value, perPage.value, currentPage.value)
  }

  function removeRole(roleId) {
    roles.value = roles.value.filter(x => x.id != roleId);
    roles.value.sort(function (a, b) {
      return a.id - b.id;
    });

    filteredRoles.value = paginateArray(roles.value, perPage.value, currentPage.value)
  }

  const fetchRoles = () => {
    if(roles.value?.length) {
      return;
    }
    useJwt.axiosIns.get(routes.ROLE)
      .then(({data}) => {
        roles.value = data;
        filteredRoles.value = paginateArray(roles.value, perPage.value, currentPage.value)
        totalRoles.value = data.length
      })
      .catch(({response}) => {
        console.log(response);
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching roles',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const addRole = (data) => {
    return new Promise((resolve, reject) => {
      useJwt.axiosIns.post(routes.ROLE, data)
        .then(({ data }) => {
          setRole(data)
          toast({
            component: ToastificationContent,
            props: {
              title: `Role added successfully`,
              icon: 'CoffeeIcon',
              variant: 'success',
            },
          })
          resolve(data)
        })
        .catch(({ response }) => {
          reject(response.data.message);
        });

    })
  }
  const updateRole = (data) => {
    return new Promise((resolve, reject) => {
      useJwt.axiosIns.put(routes.ROLE+`/${data.id}`, data)
        .then(({ data }) => {
          setRole(data)
          toast({
            component: ToastificationContent,
            props: {
              title: `Update Successfull`,
              icon: 'CoffeeIcon',
              variant: 'success',
            },
          })
          resolve(data)
        })
        .catch(({ response }) => {
          reject(response.data.message);
        });

    })
}
  
    const deleteRole = (data) => {
    return new Promise((resolve, reject) => {
      useJwt.axiosIns.delete(routes.ROLE+`/${data.id}`, data)
        .then(({ data }) => {
          removeRole(data.id)
          toast({
            component: ToastificationContent,
            props: {
              title: `Update Successfull`,
              icon: 'CoffeeIcon',
              variant: 'success',
            },
          })
          resolve(data)
        })
        .catch(({ response }) => {
          reject(response.data.message);
        });

    })
  }

  return {
    fetchRoles,
    addRole,
    updateRole,
    deleteRole,
    tableColumns,
    perPage,
    currentPage,
    totalRoles,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refRoleTable,

    setRole,
    removeRole,
    refetchData,
    refreshData,
    resolveRoleStatusBadge,
    roles: computed(() => filteredRoles.value)
  }
}
