<template>
    <b-sidebar
      id="update-admin-password-sidebar"
      :visible="isUpdateAdminPasswordSidebarActive"
      bg-variant="white"
      sidebar-class="sidebar-lg"
      shadow
      backdrop
      no-header
      right
      @hidden="resetForm"
      @change="onFormToggle"
    >
      <template #default="{ hide }">
        <!-- Header -->
        <div
          class="
            d-flex
            justify-content-between
            align-items-center
            content-sidebar-header
            px-2
            py-1
          "
        >
          <h5 class="mb-0">Update Password</h5>
  
          <feather-icon
            class="ml-1 cursor-pointer"
            icon="XIcon"
            size="16"
            @click="hide"
          />
        </div>
  
        <!-- BODY -->
        <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
          <!-- Form -->
          <b-form
            class="p-2"
            @submit.prevent="handleSubmit(onSubmit)"
            @reset.prevent="resetForm"
          >
  
             <!-- Password -->
             <validation-provider 
              #default="validationContext"
              name="Password"
              rules="required|password"
              vid="password"
            >
              <b-form-group
                label="Password"
                label-for="password"
                :state="getValidationState(validationContext)"
              >
               <b-form-input
                  id="updatePassword"
                  v-model="adminData.password"
                  :type="passwordFieldType"
                  autofocus
                  trim
                  :state="getValidationState(validationContext)"
                  placeholder=".........."
                  class="form-control-merge"
                />
                  <feather-icon
                    class="cursor-pointer"
                    :icon="passwordToggleIcon"
                    @click="togglePasswordVisibility"
                  />
                <b-form-invalid-feedback
                  :state="getValidationState(validationContext)"
                >
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
  
              <!-- Confirm Password -->
              <validation-provider
              #default="validationContext"
              name="ConfirmPassword"
              rules="required|password|confirmed:password"
            >
              <b-form-group
                label="Confirm Password"
                label-for="confirmPassword"
                :state="getValidationState(validationContext)"
              >
               <b-form-input
                  id="updateConfirmPassword"
                  v-model="adminData.confirmPassword"
                  :type="confirmPasswordFieldType"
                  autofocus
                  trim
                  :state="getValidationState(validationContext)"
                  placeholder=".........."
                  class="form-control-merge"
                />
                  <feather-icon
                    class="cursor-pointer"
                    :icon="confirmPasswordToggleIcon"
                    @click="toggleConfirmPasswordVisibility"
                  />
                <b-form-invalid-feedback
                  :state="getValidationState(validationContext)"
                >
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
  
            <!-- Form Actions -->
            <div class="d-flex mt-2">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-2"
                type="submit"
              >
                Update
              </b-button>
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="button"
                variant="outline-secondary"
                @click="hide"
              >
                Cancel
              </b-button>
            </div>
          </b-form>
        </validation-observer>
      </template>
    </b-sidebar>
  </template>
  
  <script>
  import {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BInputGroupAppend,
    BButton,
  } from "bootstrap-vue";
  import { ValidationProvider, ValidationObserver } from "vee-validate";
  import { onMounted, ref } from "@vue/composition-api";
  import { required, alphaNum, email, alpha, password, confirmed } from "@validations";
  import formValidation from "@core/comp-functions/forms/form-validation";
  import Ripple from "vue-ripple-directive";
  import vSelect from "vue-select";
  import countries from "@/@fake-db/data/other/countries";
  import useAdmin from "../useAdmin";
  import { useToast } from "vue-toastification/composition";
  import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
  import { togglePasswordVisibility } from '@core/mixins/ui/forms'
  
  export default {
    components: {
      BSidebar,
      BForm,
      BFormGroup,
      BFormInput,
      BFormInvalidFeedback,
      BInputGroupAppend,
      BButton,
      vSelect,
  
      // Form Validation
      ValidationProvider,
      ValidationObserver,
    },
    directives: {
      Ripple,
    },
    model: {
      prop: "isUpdateAdminPasswordSidebarActive",
      event: "update:is-update-admin-password-sidebar-active",
    },
    props: {
      isUpdateAdminPasswordSidebarActive: {
        type: Boolean,
        required: true,
      },
      adminToUpdate: {
        type: Object,
        required: false,
      }
    },
    mixins: [togglePasswordVisibility],
    data() {
      return {
        required,
        alphaNum,
        alpha,
        email,
        countries,
        password,
        confirmed
      };
    },
    computed: {
      passwordToggleIcon() {
        return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
      },
      confirmPasswordToggleIcon() {
        return this.confirmPasswordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
      }
    },
    setup(props, { emit }) {
    
      const toast = useToast();
  
      let blankAdminData = {
        id: null,
        password: null,
        confirmPassword: null
      };
  
  
      const adminData = ref(JSON.parse(JSON.stringify(blankAdminData)));
      const resetAdminData = () => {
        adminData.value = JSON.parse(JSON.stringify(blankAdminData));
      };
  
      const onSubmit = () => {
        dispatchUpdateAdmin();
      };
  
      const dispatchUpdateAdmin = () => {
        updateAdminPassword(adminData.value)
          .then((_) => {
             emit("update:is-update-admin-password-sidebar-active", false);
          })
          .catch((message) => {
            toast({
              component: ToastificationContent,
              props: {
                title: "Error: " + message,
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            });
          });
      };
  
      const onFormToggle = (value) => {
          emit('update:is-update-admin-password-sidebar-active', value)
          if(value) {
            blankAdminData = {
                id: props.adminToUpdate.id,
                password: null,
                confirmPassword: null
            }; 
            adminData.value = blankAdminData
          }
      }
  
      const { refFormObserver, getValidationState, resetForm } =
        formValidation(resetAdminData);
  
      const { updateAdminPassword } = useAdmin();
  
      return {
        adminData,
        onSubmit,
        refFormObserver,
        getValidationState,
        resetForm,
        onFormToggle
      };
    },
  };
  </script>
  
  <style lang="scss">
  @import "@core/scss/vue/libs/vue-select.scss";
  
  #update-admin-password-sidebar {
    .vs__dropdown-menu {
      max-height: 200px !important;
    }
  }
  </style>
  