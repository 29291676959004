import { ref, watch, computed, reactive } from '@vue/composition-api'
import useJwt from '@/auth/jwt/useJwt'
import routes from '@/@core/utils/api-routes'
import { paginateArray, sortCompare } from '@core/utils/utils'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

const admins = ref([])
const filteredAdmins = ref([])

export default function useAdmin() {
  // Use toast
  const toast = useToast()

  const refAdminTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'id', sortable: true, stickyColumn: true },
    { key: 'fullName', sortable: true },
    { key: 'email', sortable: true },
    { key: 'state', sortable: true },
    { key: 'roles', sortable: true },
    { key: 'isSuspended', label: 'Status', sortable: true },
    { key: 'actions', },
  ]
  const perPage = ref(10)
  const totalAdmins = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)


  const dataMeta = computed(() => {
    const localItemsCount = refAdminTable.value ? refAdminTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalAdmins.value,
    }
  })

  const refetchData = () => {
    console.log("refectchData")
    refAdminTable.value.refresh()
  }

  const refreshData = () => {
    admins.value = []
    fetchAdmins()
  }

  const resolveAdminStatusBadge = status => {
    if (status === true) return 'success'
    if (status === false) return 'secondary'
  }

  watch([currentPage, perPage, searchQuery], () => {
    const queryLowered = searchQuery.value.toLowerCase()
    const filteredData = admins.value.filter(
      admin => admin.fullName.toLowerCase().includes(queryLowered) ||
        admin.state.toLowerCase().includes(queryLowered) ||
        admin.email.toLowerCase().includes(queryLowered) || admin.roles.some(role => { return role.name.toLowerCase().includes(queryLowered) }))

    filteredAdmins.value = paginateArray(filteredData, perPage.value, currentPage.value)
    totalAdmins.value = filteredData.length
    refetchData()
  })

  function setAdmin(admin) {
    admins.value = admins.value.filter(x => x.id != admin.id);
    admins.value.push(admin);
    admins.value.sort(function (a, b) {
      return a.id - b.id;
    });

    filteredAdmins.value = paginateArray(admins.value, perPage.value, currentPage.value)
  }

  function removeAdmin(adminId) {
    admins.value = admins.value.filter(x => x.id != adminId);
    admins.value.sort(function (a, b) {
      return a.id - b.id;
    });

    filteredAdmins.value = paginateArray(admins.value, perPage.value, currentPage.value)
  }

  const fetchAdmins = () => {
    if (admins.value?.length) {
      return;
    }
    useJwt.axiosIns.get(routes.ADMIN)
      .then(({ data }) => {
        admins.value = data;
        filteredAdmins.value = paginateArray(admins.value, perPage.value, currentPage.value)
        totalAdmins.value = data.length
      })
      .catch(({ response }) => {
        // console.log(response);
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching admins',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const addAdmin = (data) => {
    return new Promise((resolve, reject) => {
      useJwt.axiosIns.post(routes.ADMIN, data)
        .then(({ data }) => {
          setAdmin(data)
          toast({
            component: ToastificationContent,
            props: {
              title: `Admin added successfully`,
              icon: 'CoffeeIcon',
              variant: 'success',
            },
          })
          resolve(data)
        })
        .catch(({ response }) => {
          reject(response.data.message);
        });

    })
  }
  const updateAdmin = (data) => {
    return new Promise((resolve, reject) => {
      useJwt.axiosIns.put(routes.ADMIN + `/${data.id}`, data)
        .then(({ data }) => {
          setAdmin(data)
          toast({
            component: ToastificationContent,
            props: {
              title: `Update Successfull`,
              icon: 'CoffeeIcon',
              variant: 'success',
            },
          })
          resolve(data)
        })
        .catch(({ response }) => {
          reject(response.data.message);
        });

    })
  }

  const updateAdminPassword = ({id, password}) => {
    console.log(id, password)
    return new Promise((resolve, reject) => {
      useJwt.axiosIns.post(`${routes.ADMIN}/${id}/update-password`, {password})
        .then(({_}) => {
          toast({
            component: ToastificationContent,
            props: {
              title: `Update Successfull`,
              icon: 'CoffeeIcon',
              variant: 'success',
            },
          })
          resolve(_)
        })
        .catch(({response}) => {
          reject(response.data.message);
        })
    })
  }

  const updateSuspensionStatus = (id) => {
    useJwt.axiosIns.post(`${routes.ADMIN}/${id}/toggle-suspension`, {})
      .then(({data}) => {
        if(data) {
          setAdmin(data)
        }
      })
      .catch(({response}) => {
        reject(response.data.message);
      })
  }

  const deleteAdmin = (data) => {
    return new Promise((resolve, reject) => {
      useJwt.axiosIns.delete(routes.ADMIN + `/${data.id}`, data)
        .then(({ data }) => {
          removeAdmin(data.id)
          toast({
            component: ToastificationContent,
            props: {
              title: `Update Successfull`,
              icon: 'CoffeeIcon',
              variant: 'success',
            },
          })
          resolve(data)
        })
        .catch(({ response }) => {
          reject(response.data.message);
        });

    })
  }

  return {
    fetchAdmins,
    addAdmin,
    updateAdmin,
    updateAdminPassword,
    deleteAdmin,
    tableColumns,
    perPage,
    currentPage,
    totalAdmins,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refAdminTable,
    updateSuspensionStatus,
    setAdmin,
    removeAdmin,
    refetchData,
    refreshData,
    resolveAdminStatusBadge,
    admins: computed(() => filteredAdmins.value)
  }
}
